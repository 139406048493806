import Properties from '../services/properties'

export default {
  async connectProperty(data) {
    let property

    try {
      property = await Properties.connectProperty(data)
    } catch (error) {
      property = false
    }

    return property ? property.data : false
  }
}
