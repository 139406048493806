import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import logo from '../assets/logo_verde.png'
import MenuItem from '@material-ui/core/MenuItem'
import Menu from '@material-ui/core/Menu'
import { IntlActions, withTranslate } from 'react-redux-multilingual'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import IconButton from '@material-ui/core/IconButton'
import getIcon from '../helpers/icons'

class CustomAppBar extends React.Component {
  state = {
    anchorEl: null
  }

  handleMenu = (event) => {
    this.setState({ anchorEl: event.currentTarget })
  }

  handleClose = () => {
    this.setState({ anchorEl: null })
  }

  render() {
    const { classes, dispatch, locale, customText } = this.props
    const { anchorEl } = this.state
    const open = Boolean(anchorEl)

    return (
      <AppBar position="static" color="default" className={classes.appBar}>
        <Toolbar>
          <img src={logo} alt="logo" height="30" width="100" />
          <div>
            <IconButton
              aria-owns={open ? 'menu-appbar' : undefined}
              aria-haspopup="true"
              onClick={this.handleMenu}
              color="inherit"
            >
              {getIcon(locale)}
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right'
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right'
              }}
              open={open}
              onClose={this.handleClose}
            >
              <MenuItem
                selected={locale === 'pt'}
                onClick={() => {
                  dispatch(IntlActions.setLocale('pt'))
                }}
              >
                Português
              </MenuItem>
              <MenuItem
                selected={locale === 'es'}
                onClick={() => {
                  dispatch(IntlActions.setLocale('es'))
                }}
              >
                Español
              </MenuItem>
              <MenuItem
                selected={locale === 'en'}
                onClick={() => {
                  dispatch(IntlActions.setLocale('en'))
                }}
              >
                English
              </MenuItem>
            </Menu>
          </div>
          {customText && (
            <div style={{ padding: 20 }}>
              <Typography className={classes.heading}>
                {customText}
              </Typography>
            </div>
          )}
        </Toolbar>
      </AppBar>
    )
  }
}

const styles = (theme) => ({
  appBar: {
    position: 'relative',
    backgroundColor: '#303e46',
    boxShadow: 'none'
  },
  heading: {
    color: '#fff',
    textAlign: 'center',
    fontSize: '12px'
  }
})

function mapPropsToState(state) {
  const { Intl } = state
  return { ...Intl, key: Intl.locale }
}

export default compose(
  withStyles(styles),
  connect(mapPropsToState)
)(withTranslate(CustomAppBar))
