import React from 'react'
import './App.css'
import 'react-credit-cards/es/styles-compiled.css'
import queryString from 'query-string'
import { Base64 } from 'js-base64'
import Home from './components/Home'
import StringHelper from './helpers/string-helper'

class App extends React.Component {
  render() {
    sessionStorage.clear()

    let url = window.location.href,
      params = queryString.parseUrl(url)

    try {
      let uri = Base64.decode(params.query.edp);
      let paramuri = queryString.parseUrl(uri);
      let index = paramuri.url.indexOf('payment');
      let domain = paramuri.url.substring(0, index);
      let token = paramuri.query.token;

      // console.log('paramuri.url', paramuri.url)

      sessionStorage.setItem('url', domain)
      sessionStorage.setItem('bookingId', paramuri.query.bookingId)
      sessionStorage.setItem('propertyId', paramuri.query.propertyId)
      sessionStorage.setItem('paymentAmount', paramuri.query.amount)
      sessionStorage.setItem('token', token)

      if( StringHelper.isEmpty(token) ){
        throw new Error("Token empty");
      }

      return <Home propertyId={true} bookingId={true} loading={true} />

    } catch (ex) {

      return <Home propertyId={false} bookingId={false} loading={false} />
    }
  }
}

export default App
