export const translations = {
  pt: {
    locale: 'pt-br',
    messages: {
      additionalFieldHelperText: 'Digite as informações adicionais',
      additionalFieldLabel: 'Informações adicionais',
      tableHeaderResId: 'ID da reserva',
      tableHeaderName: 'Nome',
      findYourReservationByNameOrEmail: 'Encontre sua reserva pelo email',
      clickHere: 'Clique aqui',
      or: 'ou',
      localizadorNotFound: 'Não foi possível encontrar a reserva',
      txtName: 'Nome',
      txtEmail: 'E-mail',
      localizadorLabel: 'Localizador da Reserva',
      localizadorError: 'Insira o localizador da reserva',
      previousLabel: 'Retornar',
      nextLabel: 'Avançar',
      saveLabel: 'Salvar',
      payLabel: 'Pagar',
      reservationLabel: 'Reserva ',
      firstNameLabel: 'Nome',
      firstNameHelperText: 'Digite seu nome',
      lastNameLabel: 'Sobrenome',
      lastNameHelperText: 'Digite seu sobrenome',
      mandatoryField: 'Necessário',
      acceptTermsLabel: 'Ler e aceitar os termos e condições',
      acceptTermsHelperText: 'Você deve aceitar os termos',
      successTitle: 'Pagamento',
      successSubTitle: 'Pagamento realizado com sucesso',
      successText: 'Suas informações foram registradas com sucesso.',
      moreAboutYouLabel: 'Conte-nos um pouco mais sobre você',
      errorTitle: 'Erro!',
      errorSubTitle: 'Ocorreu um erro',
      errorText: 'Erro inesperado ao tentar salvar, clique em prosseguir',
      generalErrorMessage: 'Erro inesperado. Entre em contato com a propriedade.',
      dateformat: 'dd/MM/yyyy',
      termsAndConditionsTitle: 'Termos e Condições',
      addressLabel: 'Endereço',
      addressHelperText: 'Digite seu endereço',
      numberLabel: 'Número',
      numberHelperText: 'Digite o número do seu endereço',
      complementLabel: 'Complemento',
      complementHelperText: 'Digite o complemento do seu endereço',
      neighborhoodLabel: 'Bairro',
      neighborhoodHelperText: 'Digite seu bairro',
      taxIdLabel: 'CPF',
      taxIdHelperText: 'Digite seu CPF',
      paymentLabel: 'Pagamento',
      ccCardNumberLabel: 'Número do Cartão',
      ccFullNameLabel: 'Nome',
      ccValidThruLabel: 'Validade',
      ccCodeLabel: 'CVC',
      ccYourName: 'Seu Nome',
      ccPaymentAmount: 'Valor a Pagar',
      paymentInfoAction: 'clique AQUI',
      ccCardNumberInputHelperText: 'O número informado não é válido.',
      ccFullNameInputHelperText: 'Informe o nome impresso no cartão.',
      ccValidThruInputHelperText: 'Informe uma data válida.',
      ccCodeInputHelperText: 'Informe um código válido.',
      followToPayment: 'Prosseguir para o pagamento',
      installments: 'Parcelas',
      installmentsChoose: 'Em quantas vezes?',
      installmentsFee: 'Taxa de parcelamento',
      totalInQuotes: 'Total a pagar parcelado',
      balanceSmallerThanPaymentError: 'Valor maior do que o saldo atual da reserva, entre em contato com a propriedade.'
    }
  },
  en: {
    locale: 'en-US',
    messages: {
      additionalFieldHelperText: 'Enter additional information',
      additionalFieldLabel: 'Additional Information',
      tableHeaderResId: 'Reservation ID',
      tableHeaderName: 'Name',
      findYourReservationByNameOrEmail: 'Find your reservation by its e-mail',
      clickHere: 'Click here',
      or: 'or',
      txtName: 'Name',
      txtEmail: 'E-mail',
      localizadorNotFound: 'Booking Not Found',
      localizadorLabel: 'Booking Reference',
      localizadorError: 'Enter Reservation Locator',
      previousLabel: 'Previous',
      nextLabel: 'Next',
      saveLabel: 'Save',
      payLabel: 'Pay',
      reservationLabel: 'Reservation ',
      firstNameLabel: 'First Name',
      firstNameHelperText: 'Type your name',
      lastNameLabel: 'Last Name',
      lastNameHelperText: 'Type your last name',
      mandatoryField: 'Required',
      acceptTermsLabel: 'Read and accept the terms and conditions',
      acceptTermsHelperText: 'You must accept the terms and conditions',
      successTitle: 'Payment',
      successSubTitle: 'Payment made successfully',
      successText: 'Your information has been successfully saved',
      moreAboutYouLabel: 'Tell us a little more about yourself',
      errorTitle: 'Sorry',
      errorSubTitle: 'Error',
      errorText: 'Something went wrong trying to save, click Next',
      generalErrorMessage: 'Unexpected error. Please contact the property.',
      dateformat: 'MM/dd/yyyy',
      termsAndConditionsTitle: 'Terms and Conditions',
      addressLabel: 'Address',
      addressHelperText: 'Type your address',
      numberLabel: 'Number',
      numberHelperText: 'Type your address number',
      complementLabel: 'Complement',
      complementHelperText: 'Type your address complement',
      neighborhoodLabel: 'Neighborhood',
      neighborhoodHelperText: 'Type your neighborhood',
      taxIdLabel: 'Tax ID',
      taxIdHelperText: 'Type your Tax ID',
      paymentLabel: 'Payment',
      ccCardNumberLabel: 'Card Number',
      ccFullNameLabel: 'Holder Name',
      ccValidThruLabel: 'Valid Thru',
      ccCodeLabel: 'CVC',
      ccYourName: 'Your Name',
      ccPaymentAmount: 'Payment Amount',
      paymentInfoAction: 'click HERE',
      ccCardNumberInputHelperText: 'The informed number is not valid.',
      ccFullNameInputHelperText: 'Inform the name printed in the card.',
      ccValidThruInputHelperText: 'Inform a valid date.',
      ccCodeInputHelperText: 'Inform a valid code.',
      followToPayment: 'Go to payment screen',
      installments: 'Installments',
      installmentsChoose: 'In how many installments?',
      installmentsFee: 'Installment fee',
      totalInQuotes: 'Total to pay in installments',
      balanceSmallerThanPaymentError: 'Amount greater than current reservation balance, please contact property.'
    }
  },
  es: {
    locale: 'es-ES',
    messages: {
      additionalFieldHelperText: 'Ingrese información adicional',
      additionalFieldLabel: 'Informaciones adicionales',
      tableHeaderResId: 'ID de reserva',
      tableHeaderName: 'Nombre',
      findYourReservationByNameOrEmail:
        'Encuentra tu reserva por el correo electrónico',
      clickHere: 'haga clic aquí',
      or: 'ou',
      txtName: 'Nombre',
      txtEmail: 'E-mail',
      localizadorNotFound: 'No se encontró la reserva',
      localizadorLabel: 'Localizador de Reserva',
      localizadorError: 'Ingrese el localizador de la reserva',
      previousLabel: 'Anterior',
      nextLabel: 'Siguiente',
      saveLabel: 'Guardar',
      payLabel: 'Pagar',
      reservationLabel: 'Reservación ',
      firstNameLabel: 'Nombre',
      firstNameHelperText: 'Ingrese su nombre',
      lastNameLabel: 'Apellido',
      lastNameHelperText: 'Ingrese su apellido',
      mandatoryField: 'Requerido',
      acceptTermsLabel: 'Leer y aceptar los términos y condiciones',
      acceptTermsHelperText: 'Debe aceptar los términos y condiciones',
      successTitle: 'Pago',
      successSubTitle: 'Pago realizado con éxito.',
      successText: 'Su información ha sido grabada con éxito',
      moreAboutYouLabel: 'Cuentanos mas sobre ti',
      errorTitle: 'Error!',
      errorSubTitle: 'Ocurrió un error',
      errorText: 'Ocurrió un error al intentar guardar, haz clic en Siguiente',
      generalErrorMessage: 'Error inesperado. Por favor contacte con la propiedad.',
      dateformat: 'dd/MM/yyyy',
      termsAndConditionsTitle: 'Términos y Condiciones',
      addressHelperText: 'Ingrese su dirección',
      numberLabel: 'Número',
      numberHelperText: 'Digite o número do seu endereço',
      complementLabel: 'Complemento',
      complementHelperText: 'Digite o complemento do seu endereço',
      neighborhoodLabel: 'Bairro',
      neighborhoodHelperText: 'Digite seu bairro',
      taxIdLabel: 'CPF',
      taxIdHelperText: 'Digite seu CPF',
      paymentLabel: 'Pago',
      ccCardNumberLabel: 'Numero de tarjeta',
      ccFullNameLabel: 'Nombre',
      ccValidThruLabel: 'Validez',
      ccCodeLabel: 'CVC',
      ccYourName: 'Nombre',
      ccPaymentAmount: 'Valor a Pagar',
      paymentInfoAction: 'haga clic AQUÍ',
      ccCardNumberInputHelperText: 'El número informado no es válido.',
      ccFullNameInputHelperText: 'Informar el nombre impreso en la tarjeta.',
      ccValidThruInputHelperText: 'Informar una fecha válida.',
      ccCodeInputHelperText: 'Informar un código válido.',
      followToPayment: 'Avanzar y pagar',
      installments: 'Cuotas',
      installmentsChoose: '¿En cuantas cuotas?',
      installmentsFee: 'Tarifa de pago en cuotas',
      totalInQuotes: 'Total a pagar en cuotas',
      balanceSmallerThanPaymentError: 'Pago mayor que el saldo actual de la reserva, comuníquese con la propiedad.'
    }
  }
}
