import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import { withTranslate } from 'react-redux-multilingual'
import { withStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import Reservation from '../modules/reservation'
import BookingInfoPayment from './BookingInfoPayment'
import * as CreditCardValidator from 'card-validator'

import Loading from './Loading'
import Success from './Success'
import Error from './Error'
import ErrorMessage from './ErrorMessage'
import { CardContent, Grid } from '@material-ui/core'
import StringHelper from '../helpers/string-helper'

class PaymentStep extends React.Component {
  state = {
    loading: true,
    activeStep: 'guest-payment',
    bookingInfo: {
      id: -1,
      check_in_date: null,
      check_out_date: null,
      name: '',
      edp: '',
      debit: 0
    },
    payment: {
      cc_number: '',
      cc_name: '',
      cc_valid_thru: '',
      cc_code: '',
      cc_issuer: '',
      cc_payment_amount: 0,
      cc_installments: 1
    },
    propertyInfo: {
      property_name: ''
    },
    valid: true,
    isValid: true,
    creditCardValidationStatus: {
      number: true,
      name: true,
      expiration: true,
      cvv: true,
      amount: true,
      installments: true
    },
    save_success: false,
    save_message: '',
    dateIn: new Date(),
    dateOut: new Date(),
    error_message: null
  }

  constructor(props) {
    super(props)

    this.validate = this.validate.bind(this)
    this.saveForm = this.saveForm.bind(this)
    this.resetSteps = this.resetSteps.bind(this)
  }

  async componentDidMount() {
    const defaultLocale = this.props.locale

    const reservation = await Reservation.getReservation(defaultLocale)

    if (reservation) {
      const dateIn = new Date(
        Date.UTC(
          +reservation.result.bookingInfo.check_in_date.split('-')[0],
          +reservation.result.bookingInfo.check_in_date.split('-')[1] - 1,
          parseInt(
            reservation.result.bookingInfo.check_in_date.split('-')[2],
            10
          ) + 1,
          0,
          0,
          0
        )
      )

      const dateOut = new Date(
        Date.UTC(
          +reservation.result.bookingInfo.check_out_date.split('-')[0],
          +reservation.result.bookingInfo.check_out_date.split('-')[1] - 1,
          parseInt(
            reservation.result.bookingInfo.check_out_date.split('-')[2],
            10
          ) + 1,
          0,
          0,
          0
        )
      )

      this.setState({
        bookingInfo: reservation.result.bookingInfo,
        payment: {
          ...reservation.result.bookingInfo,
          cc_payment_amount: sessionStorage.getItem('paymentAmount')
        },
        propertyInfo: reservation.result.propertyInfo,
        dateIn: dateIn,
        dateOut: dateOut
      })
    }

    this.setState({ loading: false })
  }

  render() {
    const { classes, translate } = this.props
    const { activeStep } = this.state

    return (
      <div className={classes.root}>
        {/* payment Header ( Booking main info ) */}
        <Paper square elevation={0} className={classes.header}>
          <div
            className={classes.div}
            style={{ display: this.state.loading ? 'none' : 'flex' }}
          >
            <Typography className={classes.title} component="p">
              {translate('reservationLabel')}
              <br /> {this.state.bookingInfo.tbk_id}
            </Typography>
            <Typography className={classes.title} component="p">
              Check in
              <br />{' '}
              {new Intl.DateTimeFormat(this.props.locale, {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit'
              }).format(this.state.dateIn)}{' '}
            </Typography>
            <Typography className={classes.title} component="p">
              Check out
              <br />{' '}
              {new Intl.DateTimeFormat(this.props.locale, {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit'
              }).format(this.state.dateOut)}{' '}
            </Typography>
          </div>
        </Paper>

        {this.state.loading ? (
          <Loading visible={this.state.loading} />
        ) : (
          <>
            {StringHelper.isNotEmpty(this.state.error_message) && (
              <ErrorMessage message={this.state.error_message} />
            )}
            {this.getStepContent(activeStep)}
          </>
        )}

        {activeStep !== 'success' && (
          <Grid item>
            <CardContent>
              <Button
                size="large"
                onClick={this.handleSave}
                disabled={this.state.loading}
                variant="contained"
                color="primary"
                fullWidth
              >
                {translate('payLabel')}
              </Button>
            </CardContent>
          </Grid>
        )}
      </div>
    )
  }

  handlePaymentChange = (name, value) => {
    this.setState({
      payment: {
        ...this.state.payment,
        [name]: value
      }
    })
  }

  handleChange = (event) => {
    const name = event.target.name
    this.setState((prevState) => ({
      payment: {
        ...prevState.payment,
        [name]: event.target.value
      }
    }))
  }

  handleSave = () => {
    if (this.validate()) {
      this.saveForm()
    }
  }

  resetSteps = () => {
    let payment = {}

    payment.cc_number = ''
    payment.cc_name = ''
    payment.cc_valid_thru = ''
    payment.cc_code = ''
    payment.cc_issuer = ''
    payment.cc_payment_amount = this.state.payment.cc_payment_amount

    this.setState({
      save_success: false,
      save_message: '',
      activeStep: 'guest-payment',
      payment: payment
    })
  }

  getStepContent(step) {
    switch (step) {
      case 'guest-payment':
      default: {
        return (
          <BookingInfoPayment
            payment={this.state.payment}
            paymentInfo={this.props.paymentInfo}
            bookingInfo={this.state.bookingInfo}
            validationStatus={this.state.creditCardValidationStatus}
            handlePaymentChange={this.handlePaymentChange}
            propertyName={this.state.propertyInfo.property_name}
          />
        )
      }

      case 'success': {
        return this.state.save_success ? (
          <Success propertyName={this.state.propertyInfo.property_name} />
        ) : (
          <Error message={this.state.error_message} />
        )
      }
    }
  }

  validate() {
    const numberValidation = CreditCardValidator.number(this.state.payment.cc_number);
    const validThruValidation = CreditCardValidator.expirationDate(this.state.payment.cc_valid_thru);
    const codeValidation = CreditCardValidator.cvv(this.state.payment.cc_code);

    let balanceValidation = true;
    this.setState({ error_message: null })
    if (parseFloat(this.state.payment.cc_payment_amount) > parseFloat(this.state.bookingInfo.debit)) {
      this.setState({ error_message: this.props.translate('balanceSmallerThanPaymentError') })
      balanceValidation = false;
    }

    if (
      !numberValidation.isValid ||
      this.state.payment.cc_name === '' ||
      !validThruValidation.isValid ||
      !codeValidation.isValid ||
      !balanceValidation
    ) {
      return false
    }

    return true
  }

  saveForm = () => {
    this.setState({ loading: true })

    const paymentData = this.state.payment

    Reservation.save(paymentData).then((response) => {

      this.setState({ error_message: null, save_success: response.status })

      if (
        response.hasOwnProperty('status') &&
        response.status === false &&
        response.result.hasOwnProperty('error_message') &&
        response.result.error_message !== ''
      ) {
        this.setState({
          error_message: response.result.error_message
        })
      } else if (!response) {
        this.setState({
          error_message: this.props.translate('generalErrorMessage')
        })
      } else if (
        response.hasOwnProperty('status') &&
        response.status === true
      ) {
        this.setState({
          error_message: null,
          save_success: true,
          activeStep: 'success'
        })
      }

      this.setState({ loading: false })
    })
  }
}

const styles = (theme) => ({
  root: {
    width: 'auto',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(550 + theme.spacing(2) * 2)]: {
      marginLeft: 'auto',
      marginRight: 'auto'
    }
  },
  header: {
    alignItems: 'center',
    padding: theme.spacing(1),
    backgroundColor: '#303e46',
    width: '100%',
    overflow: 'hidden'
  },
  title: {
    color: '#fff',
    fontSize: '11px',
    width: '33.3%',
    marginLeft: 'auto',
    marginRight: 'auto',
    overflowWrap: 'break-word',
    padding: 2
  },
  div: {
    display: 'flex',
    textAlign: 'center'
  }
})

PaymentStep.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  translations: PropTypes.object
}

function mapPropsToState(state) {
  const { Intl } = state
  return { ...Intl, key: Intl.locale }
}

export default compose(
  withStyles(styles, { withTheme: true }),
  connect(mapPropsToState)
)(withTranslate(PaymentStep))
