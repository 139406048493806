import client from './client'

export default {
  getReservation(data, config = {}) {
    const url = sessionStorage.getItem('url'),
      propertyId = sessionStorage.getItem('propertyId'),
      bookingId = sessionStorage.getItem('bookingId'),
      token = sessionStorage.getItem('token')

    return client.get(
      `${url}/payment/api/booking?propertyId=${propertyId}&bookingId=${bookingId}&language=${data}&token=${token}`,
      {},
      config
    )
  },

  save(
    data,
    config = { headers: { 'Content-Type': 'multipart/form-data' } }
  ) {
    const baseUrl = sessionStorage.getItem('url'),
      property = sessionStorage.getItem('propertyId'),
      booking = sessionStorage.getItem('bookingId'),
      token = sessionStorage.getItem('token')
      

    const url = `${baseUrl}/payment/api/save?propertyId=${property}&bookingId=${booking}&token=${token}`

    return client.post(url, data, config)
  },

}
