import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import compose from 'recompose/compose'
import { createTheme, MuiThemeProvider } from '@material-ui/core/styles'
import withStyles from '@material-ui/core/styles/withStyles'
import CssBaseline from '@material-ui/core/CssBaseline'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Collapse from '@material-ui/core/Collapse'
import PlayArrowIcon from '@material-ui/icons/PlayArrow'
import Divider from '@material-ui/core/Divider'
import ErrorRed from '../assets/error_red.png'
import Grid from '@material-ui/core/Grid'
import Reservation from '../modules/reservation'
import Properties from '../modules/properties'
import { withTranslate } from 'react-redux-multilingual'
import CustomAppBar from './CustomAppBar'
import Loading from './Loading'
import { Box } from '@material-ui/core'
import Payment from './Payment'

class Home extends React.Component {
  state = {
    loading: this.props.loading,
    //
    reservation: null,
    reservationId: '',
    connectPropertyId: null,
    connectDefaultLanguage: 'en',
    error: false,
    errorText: '',
    expanded: this.props.bookingId && this.props.propertyId,
    doPay: false,
    paymentAmount: 0.0,
    searchEmail: '',
    propertyInfo: {
      property_name: '',
      contact_email: '',
      address: '',
      phone: '',
      logo: ErrorRed
    },
    bookingInfo: {
      check_in_date: '',
      check_out_date: ''
    },
    dateIn: new Date(),
    dateOut: new Date(),
    externalData: null
  }

  constructor(props) {
    super(props)
    this.goToPayment = this.goToPayment.bind(this)
  }

  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value
    })
  }

  /**
   * get locale code from given property language
   * @param {*} language 
   * @returns string
   */
  getLocale = (language) => {
    let _locate = ''
    switch (language) {
      case 'pt-br': case 'pt':
          _locate = 'pt'
        break
      case 'es':
         _locate = 'es'
         break
      case 'en': case 'english': default:
        _locate = 'en'
        break
    }
    return _locate
  }

  async componentDidMount() {
    if (process.env.hasOwnProperty('REACT_APP_LOCATOR_CODE')) {
      this.setState({ reservationId: process.env.REACT_APP_LOCATOR_CODE })
    }

    if (this.props.propertyId && this.props.bookingId) {

      const connect = await Properties.connectProperty(sessionStorage.getItem('propertyId'))

      if (!connect) {
        
        this.setState({ loading: false, expanded: false })

      } else {
        
        const defaultLocale = this.props.Intl.locale ?? connect?.result?.default_language;
        this.props.setLocale(this.getLocale(defaultLocale)); 
        const response = await Reservation.getReservation(defaultLocale)
  
        if (!response) {
          this.setState({ loading: false, expanded: false })
        } else {
          this.setState({
            reservation: response.result,
            propertyInfo: response.result.propertyInfo,
            paymentInfo: response.result.paymentInfo,
            bookingInfo: response.result.bookingInfo,
            loading: false,
            dateIn: response.result.bookingInfo.formatted_check_in_date,
            dateOut: response.result.bookingInfo.formatted_check_out_date,
            paymentAmount: sessionStorage.getItem('paymentAmount')
          })
        }
      }      
    }
  }

  render() {
    const { classes, translate } = this.props
    const { dateIn, dateOut } = this.state

    if (this.state.doPay) {
      return <Payment paymentInfo={this.state.paymentInfo} />
    } else {
      return (
        <React.Fragment>
          <Loading visible={this.state.loading} />
          <div style={{ display: this.state.loading ? 'none' : 'block' }}>
            <MuiThemeProvider theme={theme}>
              <CssBaseline />
              <CustomAppBar />
              <main className={classes.layout}>
                <Card className={classes.card} raised={true}>
                  {this.state.expanded ? (
                    <Collapse
                      in={this.state.expanded}
                      timeout="auto"
                      unmountOnExit
                      classes={{ wrapperInner: classes.mydiv }}
                    >
                      <div className={classes.details}>
                        <div className={classes.detailsHeader}>
                          <Grid
                            item
                            xs={12}
                            sm={5}
                            className={classes.propertyLogo}
                          >
                            <Box mt={3}>
                              <img
                                className={classes.propertyLogoImg}
                                src={this.state.propertyInfo.logo}
                                alt="Property Logo"
                              />
                            </Box>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sm={7}
                            className={classes.propertyDetails}
                          >
                            <CardContent className={classes.content}>
                              <Typography component="h6" variant="h6">
                                {this.state.propertyInfo.property_name}
                              </Typography>
                              <Typography
                                variant="subtitle1"
                                color="textSecondary"
                              >
                                {this.state.propertyInfo.address}
                              </Typography>
                              <Divider className={classes.divideSection} />
                              <Typography component="h6" variant="h6">
                                {translate('reservationLabel')}
                              </Typography>
                              <Typography
                                variant="subtitle2"
                                color="textSecondary"
                              >
                                {translate('txtName')}:{' '}
                                {this.state.bookingInfo.name}
                              </Typography>
                              <Typography
                                variant="subtitle2"
                                color="textSecondary"
                              >
                                Check in: {String(dateIn)}
                              </Typography>
                              <Typography
                                variant="subtitle2"
                                color="textSecondary"
                              >
                                Check out: {String(dateOut)}{' '}
                              </Typography>
                              <Divider className={classes.divideSection} />
                              <Box mt={4}>
                                <Typography component="h6" variant="h6">
                                  {translate('ccPaymentAmount')}: R$
                                  {this.state.paymentAmount}
                                </Typography>
                              </Box>
                            </CardContent>
                          </Grid>
                        </div>

                        <Grid item className={classes.roomDetails}>
                          <CardContent className={classes.content}>
                            <Button
                              variant="contained"
                              color="default"
                              onClick={() => this.goToPayment()}
                              size="large"
                              fullWidth
                            >
                              {translate('nextLabel')}
                              <PlayArrowIcon className={classes.rightIcon} />
                            </Button>
                          </CardContent>
                        </Grid>
                      </div>
                    </Collapse>
                  ) : (
                    <CardContent>
                      <Typography variant='h6'>
                        {translate('generalErrorMessage')}
                      </Typography>
                    </CardContent>
                  )}
                </Card>
              </main>
            </MuiThemeProvider>
          </div>
        </React.Fragment>
      )
    }
  }

  goToPayment = () => {
    this.setState({
      doPay: true
    })
  }
}

const styles = (theme) => ({
  layout: {
    width: 'auto',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(800 + theme.spacing(4))]: {
      width: 800,
      marginLeft: 'auto',
      marginRight: 'auto'
    }
  },
  card: {
    maxWidth: 800,
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(800 + theme.spacing(6))]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3)
    }
  },
  details: {
    width: '100%',
    overflow: 'auto'
  },
  detailsHeader: {
    display: 'flex',
    flex: '1 1',
    flexDirection: 'row',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column'
    }
  },
  content: {
    flex: '1 0 auto'
  },
  mydiv: {
    display: 'flex'
  },
  divideSection: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  propertyDetails: {
    float: 'left'
  },
  propertyLogo: {
    textAlign: 'center',
    order: -1
  },
  propertyLogoImg: {
    maxWidth: '100%'
  }
})

const theme = createTheme({
  typography: {
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    fontSize: 14,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    useNextVariants: true
  },
  palette: {
    primary: {
      main: '#455A64'
    },
    secondary: {
      light: '#0066ff',
      main: '#0044ff',
      contrastText: '#ffcc00'
    }
  }
})

const mapStateToProps = (state) => ({ ...state })

const mapDispatchToProps = (dispatch) => {
  return {
    // dispatching plain actions
    setLocale: (locale) => dispatch({ type: 'SET_LOCALE', locale })
  }
}

Home.propTypes = {
  classes: PropTypes.object.isRequired
}

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(withTranslate(Home))
