import React from 'react'
import Grid from '@material-ui/core/Grid'
import TickInside from '../assets/tick_inside.png'
import Typography from '@material-ui/core/Typography'
import green from '@material-ui/core/colors/green'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { withTranslate } from 'react-redux-multilingual'
import { connect } from 'react-redux'
import compose from 'recompose/compose'

class Success extends React.Component {
  state = {
    open: false,
    vertical: 'top',
    horizontal: 'center'
  }

  handleClose = () => {
    this.setState({ open: false })
  }

  render() {
    const { translate } = this.props
    return (
      <React.Fragment>
        <Grid container>
          <Grid
            item
            xs={12}
            style={{
              marginTop: 20,
              textAlign: 'center'
            }}
          >
            <img src={TickInside} alt="tick" height="100" width="100" />
          </Grid>
          <Grid item xs={12} style={{ textAlign: 'center', marginTop: 30 }}>
            <Typography component="h4" variant="h4">
              {translate('successSubTitle')}
            </Typography>
          </Grid>
        </Grid>
      </React.Fragment>
    )
  }
}

Success.propTypes = {
  classes: PropTypes.object.isRequired
}

const styles = (theme) => ({
  margin: {
    margin: theme.spacing(4),
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  },
  cssRoot: {
    color: '#fff',
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[700]
    }
  },
  largeIcon: {
    width: 35,
    height: 35
  }
})

export default compose(withStyles(styles), connect())(withTranslate(Success))
