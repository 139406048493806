import Reservation from '../services/reservation'

export default {
  async getReservation(data) {
    let response

    try {
      response = await Reservation.getReservation(data)
    } catch (error) {
      response = false
    }

    return response ? response.data : false
  },

  async save(data) {
    let response = true

    try {
      const formData = new FormData()

      Object.keys(data).forEach((key) => formData.append(key, data[key]))

      response = await Reservation.save(formData)
    } catch (error) {
      response = false
    }
    return response ? response.data : false
  },

}
