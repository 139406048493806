import React from 'react'
import ReactLoading from 'react-loading'

const Loading = ({ visible }) => (
  <div
    style={{
      display: visible ? 'flex' : 'none',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh'
    }}
  >
    <ReactLoading type="bars" color="#c7d92f" height={'20%'} width={'20%'} />
  </div>
)

export default Loading
